import React from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import useMyMutation from "@/hooks/userMyMutation";
import { MAX_WATCHLIST_TEXT } from "@/constant";
import { axiosClient } from "@/lib/axiosClient";
import type { Section } from "./WatchlistMenu";

interface SectionFormData {
  name: string;
}

interface SectionModalProps {
  open: boolean;
  mode: "create" | "edit";
  watchlistId?: string;
  editingSection?: Section | null;
  onClose: () => void;
  refetchSections: () => void;
}

const SectionModal: React.FC<SectionModalProps> = ({ open, mode, watchlistId, editingSection, onClose, refetchSections }) => {
  const [form] = Form.useForm<SectionFormData>();
  const [isUpdating, setIsUpdating] = React.useState(false);

  const { mutate: createSection, isPending: isCreating } = useMyMutation({
    url: "/v1/watchlist/section",
    method: "post",
  });

  const handleSubmit = async (values: SectionFormData) => {
    try {
      if (mode === "create") {
        await createSection({
          name: values.name,
          watchlist_id: watchlistId,
        });

        notification.success({
          message: "Create Section Success",
          description: `Section "${values.name}" has been created successfully`,
        });
      } else {
        setIsUpdating(true);
        await axiosClient.put(`/v1/watchlist/section/${editingSection?.section.id}`, {
          name: values.name,
          watchlist_id: watchlistId,
        });
        setIsUpdating(false);

        notification.success({
          message: "Update Section Success",
          description: `Section "${values.name}" has been updated successfully`,
        });
      }

      refetchSections();
      handleClose();
    } catch (error: any) {
      notification.error({
        message: mode === "create" ? "Create Section Error" : "Update Section Error",
        description: error?.response?.data?.message || "Failed to process request",
      });
      setIsUpdating(false);
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title={mode === "create" ? "Add Section" : "Edit Section"}
      open={open}
      onCancel={handleClose}
      footer={null}
      width={400}
      closeIcon={<span className="text-xl">×</span>}
      className="[&_.ant-modal-content]:!pb-6"
    >
      <Form
        form={form}
        layout="vertical"
        className="flex flex-col"
        onFinish={handleSubmit}
        initialValues={
          mode === "edit"
            ? {
                name: editingSection?.section.name,
              }
            : undefined
        }
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Please input section name!" },
            { max: MAX_WATCHLIST_TEXT, message: `Name cannot be longer than ${MAX_WATCHLIST_TEXT} characters` },
          ]}
        >
          <Input
            placeholder="Section name"
            suffix={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <span className="text-gray-400">
                    {getFieldValue("name")?.length || 0}/{MAX_WATCHLIST_TEXT}
                  </span>
                )}
              </Form.Item>
            }
          />
        </Form.Item>

        <Form.Item className="mb-0 mt-4">
          <div className="flex justify-end gap-2">
            <Button onClick={handleClose} className="!h-9 px-4">
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="!bg-[#3861FB] !h-9 px-4"
              loading={isCreating || isUpdating}
            >
              {mode === "create" ? "Add" : "Save"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SectionModal;

