import React, { useEffect, useState } from "react";
import { Button, Input, message, Modal, notification, Space, Tag, Typography } from "antd";
import { QRCodeSVG } from "qrcode.react";
import { ArrowLeftOutlined, CopyOutlined, DownOutlined } from "@ant-design/icons";
import type { BlogData, INewsResponse } from "@/models/news";
import Image from "next/image";
import usdtIcon from "@/asset/images/usdt.svg";
import { useAccount, useChainId, useDisconnect, useReadContract } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { createPublicClient, createWalletClient, custom, http, parseAbi, parseUnits } from "viem";
import { bsc, bscTestnet, mainnet, polygon } from "viem/chains";
import { DONATE_ABI, ERC20_ABI } from "@/config/contracts";
import ethIcon from "@/asset/images/chains/eth.svg";
import bscIcon from "@/asset/images/chains/bsc.svg";
import solIcon from "@/asset/images/chains/solana.svg";
import baseIcon from "@/asset/images/chains/base.svg";
import polygonIcon from "@/asset/images/chains/polygon.svg";
import avalancheIcon from "@/asset/images/chains/avalanche.svg";
import optimismIcon from "@/asset/images/chains/optimism.svg";
import arbitrumIcon from "@/asset/images/chains/arbitrum.svg";
import xDaiIcon from "@/asset/images/chains/xdai.svg";
import celoIcon from "@/asset/images/chains/celo.svg";

const networkList = [
  { value: "Ethereum", icon: ethIcon, type: "EVM" },
  { value: "Polygon", icon: polygonIcon, type: "EVM" },
  { value: "Binance Smart Chain (BSC)", icon: bscIcon, type: "EVM" },
  { value: "Avalanche", icon: avalancheIcon, type: "EVM" },
  { value: "Optimism", icon: optimismIcon, type: "EVM" },
  { value: "Celo Mainnet", icon: celoIcon, type: "EVM" },
  { value: "Arbitrum One", icon: arbitrumIcon, type: "EVM" },
  { value: "Base", icon: baseIcon, type: "EVM" },
  { value: "xDai", icon: xDaiIcon, type: "EVM" },
  { value: "Solana", icon: solIcon, type: "Solana" },
];
interface DonateModalProps {
  open: boolean;
  onClose: () => void;
  data?: BlogData & INewsResponse;
}

interface Network {
  label: string;
  value: keyof typeof WALLET_TYPES;
}

const WALLET_TYPES = {
  EVM: "evm",
  SOL: "solana",
} as const;

const networks: Network[] = [
  { label: "EVM Chain", value: "EVM" },
  { label: "Solana", value: "SOL" },
];

const formatBalance = (value: any, decimals: number | undefined) => {
  if (!value || !decimals) return "0.00";
  try {
    const balance = Number(value.toString()) / Math.pow(10, decimals);
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 6,
      useGrouping: true,
    }).format(balance);
  } catch (error) {
    return "0.00";
  }
};

type Step = "network" | "amount";

const SUPPORTED_CHAINS = {
  1: mainnet,
  56: bsc,
  137: polygon,
  97: bscTestnet,
} as const;

// Add network config
const NETWORK_CONFIG = {
  mainnet: {
    USDT: process.env.NEXT_PUBLIC_MAINNET_USDT_ADDRESS,
    DONATE: process.env.NEXT_PUBLIC_MAINNET_DONATE_ADDRESS,
  },
  testnet: {
    USDT: process.env.NEXT_PUBLIC_TESTNET_USDT_ADDRESS,
    DONATE: process.env.NEXT_PUBLIC_TESTNET_DONATE_ADDRESS,
  },
};

const DonateModal: React.FC<DonateModalProps> = ({ open, onClose, data }) => {
  const [currentStep, setCurrentStep] = useState<Step>("network");
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0]);
  const [amount, setAmount] = useState<string>("");
  const [donateStatus, setDonateStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
  const [balance, setBalance] = useState<bigint>(BigInt(0));
  const [allowance, setAllowance] = useState<bigint>(BigInt(0));
  const [isSelectNetworkOpen, setIsSelectNetworkOpen] = useState(false);
  const [selectedChain, setSelectedChain] = useState<string>("Binance Smart Chain (BSC)");

  const chainId = useChainId();
  const { address, isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { disconnect } = useDisconnect();

  const recipientAddress = data?.user_id?.web_wallet?.[WALLET_TYPES[selectedNetwork.value]];
  const tokenAddress = (
    chainId ? (chainId === 97 ? NETWORK_CONFIG.testnet.USDT : NETWORK_CONFIG.mainnet.USDT) : undefined
  ) as `0x${string}`;
  const donateAddress = (
    chainId ? (chainId === 97 ? NETWORK_CONFIG.testnet.DONATE : NETWORK_CONFIG.mainnet.DONATE) : undefined
  ) as `0x${string}`;

  const { data: decimals } = useReadContract({
    address: tokenAddress,
    abi: ERC20_ABI,
    functionName: "decimals",
  });

  const chain = SUPPORTED_CHAINS[chainId as keyof typeof SUPPORTED_CHAINS] || bscTestnet;

  // Create clients based on current chain
  const publicClient = createPublicClient({
    chain,
    transport: http("https://bsc-dataseed.binance.org/"),
  });

  const walletClient = createWalletClient({
    chain,
    transport:
      typeof window !== "undefined" && window.ethereum
        ? custom(window.ethereum)
        : http("https://bsc-dataseed1.binance.org/"),
  });

  // Read allowance
  const getAllowance = async () => {
    try {
      const result = await publicClient.readContract({
        address: tokenAddress,
        abi: parseAbi(["function allowance(address,address) view returns (uint256)"]),
        functionName: "allowance",
        args: [address as `0x${string}`, donateAddress],
      });
      setAllowance(result as bigint);
    } catch (error) {
      console.error("Error reading allowance:", error);
      setAllowance(BigInt(0));
    }
  };

  // Approve token
  const handleApprove = async () => {
    try {
      if (!tokenAddress || !donateAddress) {
        console.error("Contract addresses:", { tokenAddress, donateAddress });
        throw new Error("Invalid contract addresses");
      }

      if (!address) {
        throw new Error("Please connect your wallet");
      }

      setDonateStatus("loading");

      const [account] = await walletClient.getAddresses();

      const approveAmount = parseUnits(amount || "0", 18);

      const hash = await walletClient.writeContract({
        address: tokenAddress,
        abi: parseAbi(["function approve(address,uint256)"]),
        functionName: "approve",
        args: [donateAddress, approveAmount],
        account,
      });
      const receipt = await publicClient.waitForTransactionReceipt({ hash });

      if (receipt.status === "success") {
        message.success("Approve successful.");
        await getAllowance(); // Refresh allowance
      } else {
        throw new Error("Approve failed");
      }
    } catch (error) {
      console.error("Approve error:", error);
      message.error((error as Error).message || "Error during approve");
    } finally {
      setDonateStatus("idle");
    }
  };

  // Donate
  const handleDonate = async () => {
    try {
      setDonateStatus("loading");
      const [account] = await walletClient.getAddresses();

      const hash = await walletClient.writeContract({
        address: donateAddress,
        abi: DONATE_ABI,
        functionName: "donate",
        args: [tokenAddress, recipientAddress as `0x${string}`, parseUnits(amount || "0", 18)],
        account,
      });

      const receipt = await publicClient.waitForTransactionReceipt({ hash });

      if (receipt.status === "success") {
        message.success("Donation successful! Thank you for your support.");
        setTimeout(() => onClose(), 2000);
      } else {
        throw new Error("Donation failed");
      }
    } catch (error) {
      message.error((error as Error).message || "Donation failed");
    } finally {
      setDonateStatus("idle");
    }
  };

  // Check if approval needed
  const needsApproval = allowance < parseUnits(amount || "0", 18);

  // Handle next button click
  const handleNext = () => {
    if (!isConnected) {
      openConnectModal?.();
      return;
    }

    if (!amount || isNaN(Number(amount))) {
      message.error("Please enter a valid amount");
      return;
    }

    if (needsApproval) {
      handleApprove();
    } else {
      handleDonate();
    }
  };

  const handleDirectDonate = () => {
    setCurrentStep("amount");
  };

  const handleBack = () => {
    if (currentStep === "amount") {
      setCurrentStep("network");
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!isNaN(Number(value))) {
      setAmount(value);
    }
  };

  const walletAddress = data?.user_id?.web_wallet?.[WALLET_TYPES[selectedNetwork.value]] || "";

  // Read balance
  const getBalance = async () => {
    try {
      const balance = await publicClient.readContract({
        address: tokenAddress,
        abi: parseAbi(["function balanceOf(address) view returns (uint256)"]),
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      });
      console.log("Balance from viem:", balance);
      setBalance(balance as bigint);
    } catch (error) {
      console.error("Error reading balance:", error);
      setBalance(BigInt(0));
    }
  };

  // Format balance for display
  const formattedBalance = formatBalance(balance, Number(decimals || 18));

  // Load initial data
  useEffect(() => {
    if (address && tokenAddress && chainId) {
      getBalance();
      getAllowance();
    }
  }, [address, tokenAddress, chainId]);

  const handleNetworkSelect = (network: (typeof networkList)[0]) => {
    setSelectedChain(network.value);
    setSelectedNetwork({
      label: network.value,
      value: network.type === "Solana" ? "SOL" : "EVM",
    });
    setIsSelectNetworkOpen(false);
  };

  const SelectNetworkModal = () => (
    <Modal
      title="Select Default Network"
      open={isSelectNetworkOpen}
      onCancel={() => setIsSelectNetworkOpen(false)}
      footer={null}
      width={400}
    >
      <div className="flex flex-col gap-2 max-h-[400px] overflow-auto">
        {networkList.map(network => (
          <div
            key={network.value}
            className="flex items-center gap-3 p-3 hover:bg-gray-50 rounded-lg cursor-pointer"
            onClick={() => handleNetworkSelect(network)}
          >
            <Image src={network.icon} alt={network.value} width={24} height={24} />
            <span>{network.value}</span>
          </div>
        ))}
      </div>
    </Modal>
  );

  const renderContent = () => {
    switch (currentStep) {
      case "network":
        return (
          <div className="flex flex-col gap-6">
            <Typography.Text type="secondary" className="text-center text-sm">
              Here is our unique QR code that will direct people to your payment when scanned
            </Typography.Text>

            <Space size={[8, 16]} wrap>
              {selectedChain && selectedChain !== "BSC" && selectedChain !== "ETH" && (
                <Tag className="!px-2 !py-1 !rounded-full !bg-gray-100 !border-gray-100 cursor-pointer">
                  <Space>
                    <Image
                      src={networkList.find(n => n.value === selectedChain)?.icon || ""}
                      alt={selectedChain}
                      width={16}
                      height={16}
                    />
                    <Typography.Text>{selectedChain}</Typography.Text>
                  </Space>
                </Tag>
              )}
            </Space>

            <div className="flex flex-col items-center gap-4">
              <QRCodeSVG value={walletAddress} size={200} level="H" includeMargin />

              <div className="flex gap-2 w-full">
                <input
                  value={walletAddress}
                  readOnly
                  className="flex-1 p-3 rounded-lg border border-gray-200 bg-gray-50 text-sm text-gray-400"
                />
                <Button
                  className="flex items-center justify-center w-10 h-10 bg-blue-50 border-none"
                  icon={<CopyOutlined className="text-blue-600" />}
                  onClick={() => {
                    navigator.clipboard.writeText(walletAddress);
                    notification.success({
                      message: "Copied",
                      description: "Wallet address has been copied to clipboard",
                      duration: 2,
                    });
                  }}
                />
              </div>
            </div>

            <div className="flex items-center my-2">
              <div className="flex-1 border-t-2 border-dashed border-gray-200" />
              <Typography.Text type="secondary" className="text-center text-sm px-2">
                Or
              </Typography.Text>
              <div className="flex-1 border-t-2 border-dashed border-gray-200" />
            </div>

            <Button type="primary" block size="large" onClick={handleDirectDonate}>
              Direct Donate
            </Button>
            <Typography.Text className="text-center text-gray-500 text-sm">
              Direct Donation function currently supports only USDT on the BSC mainnet, enabling token transfers
              directly to the recipient&apos;s wallet via any Wallet Providers.
            </Typography.Text>
          </div>
        );

      case "amount":
        return (
          <div className="flex flex-col gap-6">
            <div className="flex items-center justify-between gap-2">
              <div className="flex items-center gap-2 text-gray-600">
                <div className="w-2 h-2 rounded-full bg-orange-500" />
                <span>{selectedNetwork.value === "EVM" ? "BSC" : "Solana"} Mainnet</span>
              </div>
              {isConnected && (
                <Button danger onClick={() => disconnect()} size="small">
                  Disconnect
                </Button>
              )}
            </div>

            <div className="bg-gray-50 rounded-xl p-6">
              <div className="relative">
                <Input
                  size="large"
                  value={amount}
                  onChange={handleAmountChange}
                  placeholder="0"
                  type="number"
                  className="text-3xl h-auto p-0 border-none bg-transparent"
                  style={{ boxShadow: "none" }}
                />
                <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center gap-2">
                  <Image src={usdtIcon} alt="USDT" width={24} height={24} />
                  <span className="text-lg">USDT</span>
                </div>
              </div>
              <div className="text-gray-500 text-sm mt-2">Balance: {formattedBalance} USDT</div>
            </div>

            <div>
              <div className="text-gray-600 mb-1">Total</div>
              <div className="text-2xl font-semibold">{amount || "0.00"} USDT</div>
            </div>

            <Button
              type="primary"
              size="large"
              block
              onClick={handleNext}
              disabled={!amount || isNaN(Number(amount))}
              className="h-12 text-base font-medium"
              loading={donateStatus === "loading"}
            >
              {donateStatus === "loading" ? "Processing..." : needsApproval ? "Approve Token" : "Donate"}
            </Button>
          </div>
        );
    }
  };

  const modalTitle = (
    <div className="flex items-center gap-2">
      {currentStep === "amount" && <Button type="text" onClick={handleBack} icon={<ArrowLeftOutlined />} />}
      <div className="flex gap-2 items-center cursor-pointer" onClick={() => setIsSelectNetworkOpen(true)}>
        <Typography.Text>Select Network</Typography.Text>
        <DownOutlined />
      </div>
    </div>
  );

  return (
    <Modal open={open} onCancel={onClose} footer={null} title={modalTitle} width={400}>
      {renderContent()}
      <SelectNetworkModal />
    </Modal>
  );
};

export default DonateModal;
