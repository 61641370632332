"use client";

import type { PropsWithChildren } from "react";
import React, { useCallback, useState, useMemo } from "react";
import { Button, Form, Input, Modal, notification, Select } from "antd";
import useMyMutation from "@/hooks/userMyMutation";
import useAxiosQuery from "@/hooks/useMyQuery";
import { MAX_WATCHLIST_LENGTH, MAX_WATCHLIST_TEXT, MAX_WATCHLIST_TEXT_NOTE } from "@/constant";
import { axiosClient } from "@/lib/axiosClient";
import type { WatchListIF } from "@/app/[locale]/watchlists/MainWatchlist";
import type { Section, SectionResponse } from "@/app/components/watchlists/WatchlistMenu";
import { useAuth } from "@/context/AuthContext";
import SectionModal from "@/app/components/watchlists/SectionModal";
import { createContext } from "@/helpers/createContext";

const { TextArea } = Input;

interface WatchlistFormData {
  name: string;
  note: string;
}

interface TokenData {
  _id: string;
}

interface WatchlistContextType {
  // States
  selectedWatchlist: string;
  selectedSection: string;
  isModalOpen: boolean;
  modalMode: "create" | "edit";
  editingWatchlist: WatchListIF | null;
  sectionModalOpen: boolean;
  editingSection: Section | null;
  isUpdating: boolean;
  isCreating: boolean;
  form: any;
  watchlists: WatchListIF[];
  sections: Section[];
  isLoading: boolean;
  isLoadingSections: boolean;
  isAddTokenModalOpen: boolean;
  selectedTokenToAdd: string;

  // Setters
  setSelectedWatchlist: (id: string) => void;
  setSelectedSection: (id: string) => void;
  setIsModalOpen: (open: boolean) => void;
  setModalMode: (mode: "create" | "edit") => void;
  setEditingWatchlist: (watchlist: WatchListIF | null) => void;
  setSectionModalOpen: (open: boolean) => void;
  setEditingSection: (section: Section | null) => void;
  setIsAddTokenModalOpen: (open: boolean) => void;

  // Actions
  handleOpenModal: () => void;
  handleFinish: (values: WatchlistFormData) => Promise<void>;
  refetchWatchlist: () => void;
  refetchSections: () => void;
  handleDeleteSection: (section: Section) => Promise<void>;
  handleAddTokenToWatchlist: (token: TokenData) => void;
}

const [Provider, useWatchlist] = createContext<WatchlistContextType>();

export const WatchlistProvider = ({ children }: PropsWithChildren) => {
  // States
  const [selectedWatchlist, setSelectedWatchlist] = useState<string>("");
  const [selectedSection, setSelectedSection] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [editingWatchlist, setEditingWatchlist] = useState<WatchListIF | null>(null);
  const [sectionModalOpen, setSectionModalOpen] = useState(false);
  const [editingSection, setEditingSection] = useState<Section | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [form] = Form.useForm<WatchlistFormData>();
  const [isAddTokenModalOpen, setIsAddTokenModalOpen] = useState(false);
  const [selectedTokenToAdd, setSelectedTokenToAdd] = useState<string>("");
  const [selectedSectionForToken, setSelectedSectionForToken] = useState<string>("");
  // Add useAuth hook
  const { token: userToken, openLoginModal } = useAuth();

  // Queries
  const {
    data: watchlistData,
    isLoading,
    refetch: refetchWatchlist,
  } = useAxiosQuery<{
    data: {
      watchlists: WatchListIF[];
      total: number;
    };
  }>({
    url: "/v1/watchlist",
  });

  const {
    data: sectionsData,
    isLoading: isLoadingSections,
    refetch: refetchSections,
  } = useAxiosQuery<SectionResponse>(
    {
      url: "/v1/watchlist/section",
      params: { watchlist_id: selectedWatchlist },
      enabled: !!selectedWatchlist,
    },
    { queryKey: ["watchlist-sections", selectedWatchlist] },
  );

  // Mutations
  const { mutate: createWatchlist, isPending: isCreating } = useMyMutation<{ data: { id: string } }>({
    url: "/v1/watchlist",
    method: "post",
  });

  const { mutate: addTokenToSection } = useMyMutation({
    url: "/v1/watchlist/token-info/section/add",
    method: "post",
  });

  const handleOpenModal = useCallback(() => {
    const watchlistLength = watchlistData?.data?.watchlists?.length || 0;

    if (modalMode === "create" && watchlistLength >= MAX_WATCHLIST_LENGTH) {
      notification.warning({
        message: "Limit reached!",
        description: `We currently support up to ${MAX_WATCHLIST_LENGTH} watchlists`,
      });
      return;
    }

    if (modalMode === "create") {
      form.setFieldsValue({
        name: `Watchlist ${watchlistLength + 1}`,
        note: "",
      });
    }

    setIsModalOpen(true);
  }, [modalMode, watchlistData?.data?.watchlists?.length, form]);

  const handleFinish = async (values: WatchlistFormData) => {
    try {
      if (modalMode === "create") {
        const res = await createWatchlist({
          name: values.name,
          description: values.note,
        });

        if (res?.data?.id) {
          setSelectedWatchlist(res.data.id);
          refetchWatchlist();
        }
      } else if (modalMode === "edit" && editingWatchlist) {
        setIsUpdating(true);
        await axiosClient.put(`/v1/watchlist/${editingWatchlist.id}`, {
          name: values.name,
          description: values.note,
        });
        setIsUpdating(false);
        refetchWatchlist();
      }

      setIsModalOpen(false);
      setModalMode("create");
      setEditingWatchlist(null);
      form.resetFields();
    } catch (error: any) {
      notification.error({
        message: modalMode === "create" ? "Create Watchlist Error" : "Update Watchlist Error",
        description: error?.response?.data?.message || "There are error during request!",
      });
      setIsUpdating(false);
    }
  };

  const handleDeleteSection = async (section: Section) => {
    try {
      Modal.confirm({
        title: "Are you sure you want to delete this section?",
        content: "This action cannot be undone.",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        async onOk() {
          await axiosClient.delete(`/v1/watchlist/section/${section.section.id}`);
          refetchSections();
          notification.success({
            message: "Delete Section Success",
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Delete Section Error",
        description: error?.response?.data?.message,
      });
    }
  };

  const handleAddTokenToWatchlist = (token: TokenData) => {
    if (!userToken?.accessToken) {
      openLoginModal();
      return;
    }

    setSelectedTokenToAdd(token._id);
    setIsAddTokenModalOpen(true);
  };

  // Get sections for selected watchlist
  const filteredSections = useMemo(
    () => sectionsData?.data?.filter(section => section.section.watchlist_id === selectedWatchlist) || [],
    [sectionsData?.data, selectedWatchlist],
  );

  // Update the check using sections data
  const isTokenInSection = useMemo(() => {
    const selectedSection = sectionsData?.data?.find(section => section.section.id === selectedSectionForToken);
    return selectedSection?.tokens?.includes(selectedTokenToAdd as any) || false;
  }, [sectionsData?.data, selectedSectionForToken, selectedTokenToAdd]);

  // Add handleSubmitAddToken function
  const handleSubmitAddToken = async () => {
    try {
      if (!selectedWatchlist || !selectedSectionForToken) {
        notification.error({
          message: "Error",
          description: "Please select both watchlist and section",
        });
        return;
      }

      await addTokenToSection({
        token: selectedTokenToAdd,
        sectionId: selectedSectionForToken,
        watchlistId: selectedWatchlist,
      });

      notification.success({
        message: "Success",
        description: "Token added to watchlist successfully",
      });

      // Reset states
      setIsAddTokenModalOpen(false);
      setSelectedTokenToAdd("");
      setSelectedWatchlist("");
      setSelectedSectionForToken("");

      // Refresh sections data
      refetchSections();
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.response?.data?.message || "Failed to add token to watchlist",
      });
    }
  };

  const value = {
    // States
    selectedWatchlist,
    selectedSection,
    isModalOpen,
    modalMode,
    editingWatchlist,
    sectionModalOpen,
    editingSection,
    isUpdating,
    isCreating,
    form,
    watchlists: watchlistData?.data?.watchlists || [],
    sections: sectionsData?.data || [],
    isLoading,
    isLoadingSections,
    isAddTokenModalOpen,
    selectedTokenToAdd,
    selectedSectionForToken,

    // Setters
    setSelectedWatchlist,
    setSelectedSection,
    setIsModalOpen,
    setModalMode,
    setEditingWatchlist,
    setSectionModalOpen,
    setEditingSection,
    setIsAddTokenModalOpen,

    // Actions
    handleOpenModal,
    handleFinish,
    refetchWatchlist,
    refetchSections,
    handleDeleteSection,
    handleAddTokenToWatchlist,
  };

  return (
    <Provider value={value}>
      <Modal
        title={modalMode === "create" ? "Add Watchlist" : "Edit Watchlist"}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setModalMode("create");
          setEditingWatchlist(null);
          form.resetFields();
        }}
        footer={null}
        width={400}
        closeIcon={<span className="text-xl">×</span>}
        className="[&_.ant-modal-content]:!pb-6"
      >
        <Form form={form} layout="vertical" className="flex flex-col" onFinish={handleFinish}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: "Please input watchlist name!" },
              { max: MAX_WATCHLIST_TEXT, message: `Name cannot be longer than ${MAX_WATCHLIST_TEXT} characters` },
            ]}
          >
            <Input
              placeholder="Watchlist 2"
              suffix={
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <span className="text-gray-400">
                      {getFieldValue("name")?.length || 0}/{MAX_WATCHLIST_TEXT}
                    </span>
                  )}
                </Form.Item>
              }
            />
          </Form.Item>

          <Form.Item
            name="note"
            label="Note"
            rules={[
              {
                max: MAX_WATCHLIST_TEXT_NOTE,
                message: `Note cannot be longer than ${MAX_WATCHLIST_TEXT_NOTE} characters`,
              },
            ]}
          >
            <TextArea placeholder="Purpose of watchlist" rows={4} maxLength={MAX_WATCHLIST_TEXT_NOTE} showCount />
          </Form.Item>

          <Form.Item className="mb-0 mt-4">
            <div className="flex justify-end gap-2">
              <Button
                onClick={() => {
                  setIsModalOpen(false);
                  setModalMode("create");
                  setEditingWatchlist(null);
                  form.resetFields();
                }}
                className="!h-9 px-4"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="!bg-[#3861FB] !h-9 px-4"
                loading={isCreating || isUpdating}
              >
                {modalMode === "create" ? "Add" : "Save"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      {/* Add Token Modal */}
      <Modal
        title="Add Token to Watchlist"
        open={isAddTokenModalOpen}
        onCancel={() => {
          setIsAddTokenModalOpen(false);
          setSelectedWatchlist("");
          setSelectedSectionForToken("");
        }}
        footer={null}
      >
        <div className="flex flex-col gap-4">
          <Form layout="vertical" onFinish={handleSubmitAddToken}>
            <Form.Item label="Select Watchlist" rules={[{ required: true, message: "Please select a watchlist" }]}>
              <Select
                placeholder="Choose watchlist"
                value={selectedWatchlist}
                onChange={value => {
                  setSelectedWatchlist(value);
                  setSelectedSectionForToken("");
                }}
                className="w-full"
                notFoundContent={
                  <div className="text-center py-2">
                    <div className="text-gray-500">No watchlists found</div>
                    <Button type="link" onClick={handleOpenModal}>
                      Create new watchlist
                    </Button>
                  </div>
                }
              >
                {watchlistData?.data?.watchlists?.map(watchlist => (
                  <Select.Option key={watchlist.id} value={watchlist.id}>
                    {watchlist.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Select Section" rules={[{ required: true, message: "Please select a section" }]}>
              <Select
                placeholder={!selectedWatchlist ? "Please select a watchlist first" : "Choose section"}
                value={selectedSectionForToken}
                onChange={setSelectedSectionForToken}
                className="w-full"
                disabled={!selectedWatchlist}
                notFoundContent={
                  <div className="text-center py-2">
                    <div className="text-gray-500">No sections found</div>
                    <Button type="link" onClick={() => setSectionModalOpen(true)}>
                      Create new section
                    </Button>
                  </div>
                }
              >
                {filteredSections.map(section => (
                  <Select.Option key={section.section.id} value={section.section.id}>
                    {section.section.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {selectedSectionForToken && isTokenInSection && (
              <div className="text-yellow-500 text-sm mb-2">This token already exists in the selected section</div>
            )}

            <Form.Item className="mb-0 mt-4">
              <div className="flex justify-end gap-2">
                <Button onClick={() => setIsAddTokenModalOpen(false)}>Cancel</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="!bg-[#3861FB]"
                  disabled={!selectedWatchlist || !selectedSectionForToken || isTokenInSection}
                >
                  Add Token
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <SectionModal
        open={sectionModalOpen}
        mode="create"
        watchlistId={selectedWatchlist}
        onClose={() => setSectionModalOpen(false)}
        refetchSections={refetchSections}
      />
      {children}
    </Provider>
  );
};
export { useWatchlist };

