import React from "react";
import useMyQuery from "@/hooks/useMyQuery";
import type { TokenData } from "@/models/token";
import { Avatar, Typography } from "antd";
import PriceChange from "../PriceChange";
import { formatThousands } from "@/helpers";
import classNames from "classnames";
import { BASE_URL } from "@/constant/apiPath";
import { useLanguageContext } from "@/context/LanguageContext";

function TokenList() {
  const { data } = useMyQuery<{ data: TokenData[] }>({
    url: `/v1/market-movement/token-info/public/trending`,
    params: { limit: 50 },
    includeParamsInQueryKey: true,
  });
  const { language } = useLanguageContext();
  return (
    <div className="w-full overflow-hidden relative">
      <div className="whitespace-nowrap inline-block marquee">
        {/* First set of items */}
        {data?.data?.map(item => (
          <div
            key={`${item.symbol}-1`}
            className={classNames("inline-flex items-center gap-1 rounded-md px-3 py-1 mx-1 cursor-pointer", {
              "bg-red-100 text-red-700": item.price_change?.change_1D?.percentage < 0,
              "bg-green-100 !text-green-800": item.price_change?.change_1D?.percentage > 0,
            })}
            onClick={() => window.open(`${BASE_URL}/${language}/markets/${item.symbol}`, "_blank")}
          >
            <Avatar src={item.logo_uri} alt={item.symbol} size={20} />
            <div>{formatThousands(item.price, "$")}</div>
            <Typography.Text>
              (<PriceChange price={item.price_change?.change_1D?.percentage ?? 0} />)
            </Typography.Text>
          </div>
        ))}
        {/* Duplicate set for seamless scrolling */}
        {data?.data?.map(item => (
          <div
            key={`${item.symbol}-2`}
            className={classNames("inline-flex items-center gap-1 rounded-md px-3 py-1 mx-1 cursor-pointer", {
              "bg-red-100 text-red-700": item.price_change?.change_1D?.percentage < 0,
              "bg-green-100 !text-green-800": item.price_change?.change_1D?.percentage > 0,
            })}
            onClick={() => window.open(`${BASE_URL}/vi/markets/${item.symbol}`, "_blank")}
          >
            <Avatar src={item.logo_uri} alt={item.symbol} size={20} />
            <div>{formatThousands(item.price, "$")}</div>
            <Typography.Text>
              (<PriceChange price={item.price_change?.change_1D?.percentage ?? 0} />)
            </Typography.Text>
          </div>
        ))}
      </div>

      <style jsx>{`
        @keyframes marquee {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .marquee {
          animation: marquee 500s linear infinite;
        }

        .marquee:hover {
          animation-play-state: paused;
        }
      `}</style>
    </div>
  );
}

export default React.memo(TokenList);

