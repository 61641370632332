"use client";

import { usePathname } from "next/navigation";
import Header from "./header";
import Footer from "./Footer";
import KlardaAPIBanner from "@/app/components/KlardaAPIBanner";
import React from "react";
import { useLanguageContext } from "@/context/LanguageContext";
import ScrollToTop from "./ScrollTop";

const InsightLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <Header />
    <main className="w-full p-4 md:p-6">{children}</main>
  </>
);

const AdvertiseLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <Header />
    <main className="w-full p-4 md:p-6">{children}</main>
    <Footer />
  </>
);

const FullChartLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <main className="w-full h-screen">{children}</main>
);
const FullWatchlistLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <main className="w-full h-screen">
    <Header />
    <main className="mx-auto p-4 md:p-6">{children}</main>
    <KlardaAPIBanner />
    <Footer />
  </main>
);
const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <Header />
    <main className="max-w-[1440px] mx-auto p-4 md:p-6">{children}</main>
    <KlardaAPIBanner />
    <Footer />
  </>
);

const ContentWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const pathname = usePathname();
  const { language } = useLanguageContext();

  const isFullChart = pathname.includes("/full-chart");
  const isFullWatchlist = pathname.includes("/watchlists");
  const isInsightPage = pathname === `/${language}/insight`;
  const isAdvertisePage = pathname === `/${language}/advertise`;

  if (isFullChart) {
    return <FullChartLayout>{children}</FullChartLayout>;
  }
  if (isFullWatchlist) {
    return <FullWatchlistLayout>{children}</FullWatchlistLayout>;
  }

  if (isInsightPage) {
    return <InsightLayout>{children}</InsightLayout>;
  }

  if (isAdvertisePage) {
    return <AdvertiseLayout>{children}</AdvertiseLayout>;
  }

  return (
    <>
      <DefaultLayout>{children}</DefaultLayout>
      <ScrollToTop />
    </>
  );
};

export default ContentWrapper;
