import { Avatar } from "antd/lib";
import React, { useEffect, useState } from "react";
import useAxiosQuery from "@/hooks/useMyQuery";

const generateRandomAvatar = () => {
  const styles = ["avataaars", "bottts", "identicon", "initials", "micah", "personas"];
  const randomStyle = styles[Math.floor(Math.random() * styles.length)];
  const seed = Math.random().toString(36).substring(7);
  return `https://api.dicebear.com/7.x/${randomStyle}/svg?seed=${seed}&options[mood][]=happy&options[accessories][]=glasses&options[hair][]=short`;
};

interface ActiveUserProps {
  className?: string;
}

const ActiveUser: React.FC<ActiveUserProps> = ({ className }) => {
  const [avatars, setAvatars] = useState<string[]>([]);
  const [hiddenCount, setHiddenCount] = useState<number | null>(null);

  const { data: userData } = useAxiosQuery<{
    success: boolean;
    data: {
      _id: string;
      web: string;
      count: number;
      updated_at: string;
      id: string;
    };
  }>({
    url: "/v1/admin/user/user/number-by-page?type=homepage",
    method: "get",
  });

  useEffect(() => {
    setAvatars(Array.from({ length: 2 }, generateRandomAvatar));

    if (userData?.data?.count) {
      setHiddenCount(userData.data.count);
    }
  }, [userData]);

  return (
    <Avatar.Group size="default" className={className} maxCount={3}>
      {avatars.length > 0 && avatars.map((avatar, index) => <Avatar src={avatar} key={index} alt="user avt" />)}
      {hiddenCount !== null && (
        <Avatar style={{ backgroundColor: "#fde3cf", color: "#f56a00" }} alt="user avt">
          +{hiddenCount}
        </Avatar>
      )}
    </Avatar.Group>
  );
};

export default ActiveUser;

