import React, { memo, useCallback } from "react";
import { useTranslations } from "next-intl";
import { Empty, Popover, Skeleton, Typography } from "antd/lib";
import { motion } from "framer-motion";
import Link from "next/link";
import { usePrice } from "@/context/PriceContext";
import useAxiosQuery from "@/hooks/useMyQuery";
import { formatThousands, processNewsData } from "@/helpers";
import MyModal from "@/app/components/MyModal";
import MyCard from "@/app/components/MyCard";
import PriceChange from "@/app/components/PriceChange";
import TokenPrice from "@/app/components/TokenPrice";
import NewsInfo from "@/app/components/NewsInfo";
import type { IPriceChange, ISmartContract } from "@/models/token";
import type { INewsResponse } from "@/models/news";
import { useRouter } from "next/navigation";
import NotSupportedPopover from "./NotSupportedPopover";
import { useCurrencyContext } from "@/context/CurrencyContext";
import { useLanguageContext } from "@/context/LanguageContext";
import AdsBanner from "./AdsBanner";

interface SearchModalProps {
  open: boolean;
  onClose: () => void;
  searchText: string;
}

interface SearchModalProps {
  open: boolean;
  onClose: () => void;
  searchText: string;
}

interface TokenInfoData {
  coin_id_crypto_rank: number;
  id: string;
  last_24h_price: number;
  logo_uri: string;
  market_cap: number;
  name: string;
  networks: ISmartContract[];
  price: number;
  price_change: IPriceChange;
  rank: number;
  symbol: string;
  token_symbol: string;
}

interface ITrendingData {
  category: string;
  last_24h_price: number;
  logo_uri: string;
  market_cap: number;
  name: string;
  price: number;
  short_description: string;
  symbol: string;
  token_symbol: string;
  volume: number;
}
const SearchEmpty = memo(({ searchText }: { searchText: string }) => (
  <div className="w-full items-center text-center">
    <Typography.Title level={4} className="mb-2">
      No result for &quot;{searchText}&quot;
    </Typography.Title>
    <Empty description="We couldn't find anything matching your search." />
    <Typography.Text>Try again with a different term.</Typography.Text>
  </div>
));
SearchEmpty.displayName = "SearchEmpty";

const RecommendedList = memo(({ item, onClose }: { item: TokenInfoData; onClose: () => void }) => {
  const { getPrice } = usePrice();
  const { currentCurrency } = useCurrencyContext();
  const { language } = useLanguageContext();

  const router = useRouter();
  const handleClickToken = useCallback(() => {
    router.push(`/${language}/markets/${item.symbol}`);
    onClose();
  }, [item.symbol, language, onClose, router]);

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
      className="py-4 px-3 border border-opacity-10 grid sm:flex sm:items-center gap-2 rounded-lg !text-sm cursor-pointer"
      onClick={handleClickToken}
    >
      <div className="flex items-center space-x-1 col-span-2 sm:col-span-8">
        <img src={item.logo_uri} alt={`${item.name} logo`} width={32} height={32} className="rounded-full" />
        <div className="flex flex-col">
          <div className="text-black whitespace-nowrap flex gap-2">
            <span className="block font-semibold overflow-hidden text-ellipsis max-w-[100px]">{item.name}</span>
            <span className="font-normal text-opacity-85 text-[12px]">{item.token_symbol}</span>
          </div>
          {getPrice(item.symbol)?.price ? (
            <TokenPrice initPrice={item.price} symbol={item.symbol} className="!font-normal text-black" />
          ) : (
            <div className="!font-normal text-black">{formatThousands(item.price, currentCurrency)}</div>
          )}
        </div>
      </div>
      <div className="flex-1 col-span-1 sm:col-span-2">
        <PriceChange
          hasBgColor={true}
          className="min-w-fit font-bold px-2 rounded-md py-1 float-right text-nowrap"
          price={item.last_24h_price > 0 ? ((item.price - item.last_24h_price) * 100) / item.last_24h_price : 0}
        />
      </div>
    </motion.div>
  );
});
RecommendedList.displayName = "RecommendedList";

const NewsList = memo(({ item, onClose }: { item: INewsResponse; onClose: () => void }) => {
  const { title, description, imageUrl } = processNewsData(item);
  const { language } = useLanguageContext();

  const newsLink = item?.is_klarda ? `/${language}/news/blogs/${item?.link}` : `/${language}/news/${item?.id}`;

  return (
    <div className="flex flex-col lg:flex-row gap-2" onClick={onClose}>
      <Link href={newsLink} passHref>
        <img
          src={imageUrl as string}
          alt={item.title || "News Image"}
          width={100}
          className="rounded h-[76px] min-w-[110px]"
        />
      </Link>
      <div className="flex flex-col w-full gap-1">
        <Typography.Link title={title} href={newsLink}>
          <Typography.Title level={5} className="line-clamp-1 text-sm mb-0">
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </Typography.Title>
        </Typography.Link>
        <Typography.Link title={title} href={newsLink}>
          <Typography.Text title={description} type="secondary" className="line-clamp-1 text-[12px]">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Typography.Text>
        </Typography.Link>
        <NewsInfo news={item} />
      </div>
    </div>
  );
});
NewsList.displayName = "NewsList";

const SearchModal: React.FC<SearchModalProps> = memo(({ open, onClose, searchText }) => {
  const t = useTranslations();
  const { language } = useLanguageContext();

  const { data: tokensData, isLoading: isLoadingTokens } = useAxiosQuery<{ data: TokenInfoData[] }>({
    url: `/v1/market-movement/token-info/search`,
    method: "get",
    includeParamsInQueryKey: true,
    params: { q: searchText || "", limit: 5 },
  });

  const { data: newsData, isLoading: isLoadingNews } = useAxiosQuery<{ data: { news: INewsResponse[] } }>({
    url: `/v1/market-insight/public`,
    method: "get",
    includeParamsInQueryKey: true,
    params: { q: searchText || "", limit: 5 },
  });

  const { data: trendingData } = useAxiosQuery<{ data: { result: ITrendingData[] } }>({
    url: `/v1/admin/token/search/trending-public`,
    method: "get",
  });

  const renderRecommended = useCallback(
    () => (
      <MyCard title="Recommended">
        <div className="space-y-2">
          {isLoadingTokens ? (
            <Skeleton active paragraph={{ rows: 10 }} />
          ) : tokensData?.data?.length ? (
            <>
              {tokensData.data.map(item => (
                <RecommendedList key={item.id} item={item} onClose={onClose} />
              ))}
              <Typography.Link href="/markets" className="block mt-2">
                {t("home.More_token")}
              </Typography.Link>
            </>
          ) : (
            <SearchEmpty searchText={searchText} />
          )}
        </div>
      </MyCard>
    ),
    [isLoadingTokens, tokensData?.data, t, searchText, onClose],
  );

  const renderNews = useCallback(
    () => (
      <MyCard title="News">
        <div className="space-y-2">
          {isLoadingNews ? (
            <Skeleton active paragraph={{ rows: 10 }} />
          ) : newsData?.data?.news?.length ? (
            <>
              {newsData.data.news.map(item => (
                <NewsList key={item.id} item={item} onClose={onClose} />
              ))}
              <Typography.Link href={`/${language}/news/search`} className="block mt-2">
                {t("news.View_more_news")}
              </Typography.Link>
            </>
          ) : (
            <SearchEmpty searchText={searchText} />
          )}
        </div>
      </MyCard>
    ),
    [isLoadingNews, language, newsData?.data?.news, onClose, searchText, t],
  );

  return (
    <MyModal
      open={open}
      onCancel={onClose}
      footer={null}
      hasHeader={false}
      maskStyle={{ marginTop: "120px" }}
      width="fit-content"
      wrapClassName="max-w-[1037px]"
      bodyClassName="flex flex-col gap-6 p-[16px] max-h-[85vh] md:max-h-[80vh] overflow-y-auto"
      centered
      maskClosable
    >
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-5">{renderRecommended()}</div>
        <div className="col-span-12 lg:col-span-7">{renderNews()}</div>
      </div>

      <div className="flex items-center gap-3 flex-wrap bg-[#F4EFFF] rounded-lg p-4">
        <Typography.Title level={5}>Most search</Typography.Title>
        {trendingData?.data?.result?.slice(0, 5).map(item => (
          <div key={item.name} className="w-fit bg-[#C0A2FB] text-primary text-sm rounded-lg px-2 py-1">
            {item.name}
          </div>
        ))}
      </div>

      <AdsBanner isSmallSize />
    </MyModal>
  );
});

SearchModal.displayName = "SearchModal";
export default SearchModal;
