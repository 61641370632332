import React from "react";

const DonateIcon = () => (
  <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="scale-125">
    <g clipPath="url(#clip0_2966_19443)">
      <path
        d="M6.00014 5.49832C7.37924 5.49832 8.5076 4.38788 8.5076 2.99086C8.5076 1.61176 7.37924 0.483398 6.00014 0.483398C4.62103 0.483398 3.49268 1.61176 3.49268 2.99086C3.49268 4.38788 4.63894 5.49832 6.00014 5.49832ZM6.00014 3.16997C5.55238 3.16997 5.17626 2.79385 5.17626 2.34609C5.17626 1.95206 5.44491 1.62967 5.82103 1.54011V1.25355C5.82103 1.164 5.89268 1.07444 6.00014 1.07444C6.08969 1.07444 6.17924 1.164 6.17924 1.25355V1.54011C6.53745 1.62967 6.82402 1.95206 6.82402 2.34609C6.82402 2.43564 6.75238 2.52519 6.64491 2.52519C6.55536 2.52519 6.46581 2.43564 6.46581 2.34609C6.46581 2.09534 6.26879 1.89832 6.01805 1.88041H6.00014C5.74939 1.88041 5.55238 2.09534 5.55238 2.34609C5.55238 2.59683 5.7673 2.81176 6.01805 2.81176C6.46581 2.81176 6.84193 3.18788 6.84193 3.63564C6.84193 4.02967 6.55536 4.36997 6.16133 4.44161V4.72817C6.16133 4.83564 6.07178 4.90728 5.98223 4.90728C5.89268 4.90728 5.80312 4.83564 5.80312 4.72817V4.4237C5.44491 4.33414 5.19417 4.01176 5.19417 3.63564C5.19417 3.54608 5.26581 3.45653 5.37327 3.45653C5.46282 3.45653 5.55238 3.54608 5.55238 3.63564C5.55238 3.88638 5.7673 4.10131 6.01805 4.10131C6.26879 4.10131 6.48372 3.88638 6.48372 3.63564C6.48372 3.38489 6.25088 3.16997 6.00014 3.16997Z"
        fill="currentColor"
      />
      <path
        d="M10.1733 4.85356L8.54345 4.29834C8.52554 4.31625 8.47181 4.44162 8.36435 4.60282C8.40017 4.62073 8.38226 4.60282 9.56435 5.01476L6.00017 6.23267L2.43599 5.01476C3.6539 4.58491 3.61808 4.62073 3.63599 4.60282C3.54644 4.47744 3.45688 4.31625 3.43897 4.29834L1.80912 4.85356C1.75539 4.87147 1.71957 4.9073 1.70166 4.96103L6.00017 6.44759L10.2808 4.97894C10.2629 4.92521 10.227 4.87147 10.1733 4.85356Z"
        fill="currentColor"
      />
      <path
        d="M5.65965 6.71622L1.39697 5.24756L0.555176 6.30428L4.58503 7.79084L5.65965 6.71622Z"
        fill="currentColor"
      />
      <path
        d="M4.56724 8.16693L1.52246 7.03857V10.0296L5.82097 11.5162V7.0744L4.76425 8.13111C4.71052 8.16693 4.63888 8.18484 4.56724 8.16693ZM7.23589 8.13111L6.17918 7.0744V11.5162L10.4956 10.0296V7.03857L7.43291 8.16693C7.36127 8.18484 7.28962 8.16693 7.23589 8.13111Z"
        fill="currentColor"
      />
      <path
        d="M10.6029 5.24756L6.32227 6.71622L7.4148 7.79084L10.6029 6.60875L11.4447 6.30428L10.6029 5.24756Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2966_19443">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DonateIcon; 