"use client";
import React, { useCallback, useMemo, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import type { MenuProps } from "antd";
import { Button, Divider, Dropdown, Input, Menu } from "antd";
import { ApiOutlined, AppstoreOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useTranslations } from "next-intl";
import KlardaComLogo from "@/svg/KlardaComLogo";
import KlardaLogo1 from "@/svg/KlardaLogo1";
import { debounce, isEmpty } from "lodash";
import { motion } from "framer-motion";
import KlardaBubbleIcon from "@/svg/KlardaBubbleIcon";
import WhisperIcon from "@/svg/WhisperIcon";
import CalendarEventIcon from "@/svg/CalendarEventIcon";

import UserPopover from "@/app/components/UserSetting";
import { Avatar } from "antd/lib";
import { IMAGE_CDN } from "@/constant/apiPath";
import { useLanguageContext } from "@/context/LanguageContext";
import RiseOutlineIcon from "@/svg/RiseOutlineIcon";
import { useAuth } from "@/context/AuthContext";
import type { ItemType } from "antd/lib/menu/hooks/useItems";
import ActiveUser from "@/app/components/header/HeaderActiveUser";
import DisplaySize from "@/app/utils/DisplaySize";
import useResponsive from "@/app/hooks/useResponsive";
import SearchModal from "../SearchModal";
import TokenCarousel from "./TokenCarousel";
import DonateModal from "../DonateModal";
import DonateIcon from "@/app/components/icons/DonateIcon";

// Main Header Component
const Header: React.FC = () => {
  const t = useTranslations("home.header");
  const router = useRouter();
  const pathname = usePathname();
  const { authState } = useAuth();
  const { language } = useLanguageContext();
  const screenSize = useResponsive();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);

  const currentPath = useMemo(() => {
    const pathParts = pathname.split("/");
    return "/" + (pathParts.length > 2 ? pathParts.slice(2).join("/") : "");
  }, [pathname]);

  const handleMenuClick = useCallback(
    (path: string) => {
      const urlPattern = /^https?:\/\//i;
      if (urlPattern.test(path)) {
        window.open(path);
      } else {
        setIsMenuOpen(false);
        router.push(`/${language}${path}`);
      }
    },
    [language, router],
  );

  const handleMenuClickUnRedirect = useCallback(
    (path: string) => {
      const urlPattern = /^https?:\/\//i;
      if (urlPattern.test(path)) {
        window.open(path, "_self");
      } else {
        setIsMenuOpen(false);
        router.push(`/${language}${path}`);
      }
    },
    [language, router],
  );

  const toggleMenu = useCallback(() => setIsMenuOpen(prev => !prev), []);

  const handleSearchTextChange = useMemo(() => debounce((text: string) => setSearchText(text), 500), []);

  const menuItems = useMemo(
    () => [
      { key: "/markets", label: t("markets") },
      { key: "/news", label: t("news") },
      { key: "/insight", label: t("insight") },
      { key: "/etfs", label: t("etfs") },
      { key: "/watchlists", label: t("watchlists") },
      { key: "/exchange", label: t("exchange") },
      { key: "/advertise", label: t("advertise") },
    ],
    [t],
  );

  const dropdownCurrency = useMemo(() => [{ key: "usd", label: <div>USD</div> }], []);
  const dropdownTimezone = useMemo(() => [{ key: "utc", label: <div>UTC</div> }], []);

  return (
    <>
      <SearchModal open={openSearchModal} onClose={() => setOpenSearchModal(false)} searchText={searchText} />
      <div className="bg-white w-full sticky top-0 z-50 transition-all duration-300 py-1">
        <div className="bg-white w-full py-1">
          <TokenCarousel />
        </div>
        <header className={`h-full w-full max-w-[1440px] mx-auto`}>
          <div className="flex items-center px-4 py-4 lg:px-6 gap-4 relative">
            {(screenSize < DisplaySize.Tablet ? !openSearchModal : true) && (
              <div onClick={() => handleMenuClick("/")} className="cursor-pointer w-fit">
                <KlardaComLogo />
              </div>
            )}

            <SearchInput
              openSearchModal={openSearchModal}
              setOpenSearchModal={setOpenSearchModal}
              handleSearchTextChange={handleSearchTextChange}
            />
            {!openSearchModal && (
              <>
                <div className="hidden lg:flex items-center space-x-2 absolute right-6">
                  {authState === "logged" ? (
                    <UserLoggedAction dropdownCurrency={dropdownCurrency} dropdownTimezone={dropdownTimezone} />
                  ) : (
                    <GuestAction dropdownCurrency={dropdownCurrency} dropdownTimezone={dropdownTimezone} />
                  )}
                </div>
                <MenuToggle toggle={toggleMenu} isOpen={isMenuOpen} />
              </>
            )}
          </div>
          {!openSearchModal && (
            <>
              <MobileMenu
                isMenuOpen={isMenuOpen}
                current={currentPath}
                menuItems={menuItems}
                handleMenuClick={handleMenuClickUnRedirect}
                authState={authState}
                setIsDonateModalOpen={setIsDonateModalOpen}
              />
              <DesktopMenu
                current={currentPath}
                menuItems={menuItems}
                handleMenuClick={handleMenuClickUnRedirect}
                setIsDonateModalOpen={setIsDonateModalOpen}
              />
            </>
          )}
        </header>
      </div>

      <DonateModal
        open={isDonateModalOpen}
        onClose={() => setIsDonateModalOpen(false)}
        data={
          {
            id: "",
            title: "",
            thumbnail: {
              alt: "",
              description: "",
              imageKey: "",
              quote: "",
            } as any,
            content: "",
            url: "",
            user_id: {
              email: "",
              id: "",
              web_wallet: {
                evm: process.env.NEXT_PUBLIC_DONATE_EVM_ADDRESS,
                solana: process.env.NEXT_PUBLIC_DONATE_SOLANA_ADDRESS,
              },
              profile_information: {
                avatar: "",
                bio: "",
                display_name: "",
              },
            },
          } as any
        }
      />
    </>
  );
};

// SearchInput Component
const SearchInput: React.FC<{
  openSearchModal: boolean;
  setOpenSearchModal: (open: boolean) => void;
  handleSearchTextChange: (text: string) => void;
}> = React.memo(({ openSearchModal, setOpenSearchModal, handleSearchTextChange }) => {
  const [inputValue, setInputValue] = useState("");
  const screenSize = useResponsive();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    handleSearchTextChange(newValue);
  };

  const handleCloseSearch = () => {
    setOpenSearchModal(false);
    setInputValue("");
    handleSearchTextChange("");
  };

  return (
    <div
      className={`flex-grow max-w-[1570px] m-auto ${
        openSearchModal ? "flex items-center gap-2 md:gap-6" : "px-4 md:px-8 lg:pr-[20px] lg:pl-[0px]"
      }`}
    >
      <Input
        size={openSearchModal ? "large" : "middle"}
        placeholder="Search for Coin, Function, Announcement..."
        prefix={<SearchOutlined className="text-gray-400" />}
        className={`rounded-full bg-white border-gray-300 text-gray-600 placeholder-gray-400 text-sm md:text-base ${
          !openSearchModal ? "max-w-[350px] lg:max-w-[100px] xl:max-w-[350px]" : ""
        }`}
        onFocus={() => setOpenSearchModal(true)}
        onChange={handleInputChange}
        value={inputValue}
      />
      {openSearchModal && (
        <Button
          size={screenSize < DisplaySize.Tablet ? "middle" : "large"}
          type="primary"
          className="lg:px-2"
          icon={<CloseOutlined />}
          onClick={handleCloseSearch}
        />
      )}
    </div>
  );
});

SearchInput.displayName = "SearchInput";

// MenuToggle Component
const MenuToggle: React.FC<{ toggle: () => void; isOpen: boolean }> = ({ toggle, isOpen }) => (
  <button onClick={toggle} className="lg:hidden bg-transparent border-none">
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
        animate={isOpen ? "open" : "closed"}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
        animate={isOpen ? "open" : "closed"}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
        animate={isOpen ? "open" : "closed"}
      />
    </svg>
  </button>
);

const Path: React.FC<{
  d?: string;
  variants?: any;
  transition?: any;
  animate?: any;
}> = props => (
  <motion.path fill="transparent" strokeWidth="3" stroke="hsl(0, 0%, 18%)" strokeLinecap="round" {...props} />
);

// MobileMenu Component
const MobileMenu: React.FC<{
  isMenuOpen: boolean;
  current: string;
  menuItems: any[];
  handleMenuClick: (path: string) => void;
  authState: string;
  setIsDonateModalOpen: (open: boolean) => void;
}> = React.memo(({ isMenuOpen, current, menuItems, handleMenuClick, authState, setIsDonateModalOpen }) => {
  const t = useTranslations("home.header");
  const { logout, openLoginModal } = useAuth();

  const mobileMenuItems: MenuProps["items"] = [
    ...menuItems.filter(item => item.key !== "/more"),
    ...(menuItems.find(item => item.key === "/more")?.children || []),
    {
      key: "donate",
      label: (
        <Button
          icon={<DonateIcon />}
          className="border-[#046C4E] text-[#046C4E] bg-[#DEF7EC] hover:bg-[#046C4E] hover:text-white hover:border-[#046C4E] transition-colors"
          onClick={() => setIsDonateModalOpen(true)}
        >
          Donate
        </Button>
      ),
    },
    ...(authState === "logged"
      ? [
          {
            key: "acc-setting",
            label: (
              <Button type="primary" className="border-none w-full">
                {t("account_setting")}
              </Button>
            ),
          },
          {
            key: "signout",
            label: (
              <Button type="primary" className="w-full border-none text-center" onClick={logout}>
                {t("sign_out")}
              </Button>
            ),
          },
        ]
      : [
          {
            key: "sign-in",
            label: (
              <Button type="primary" className="w-full text-white border-none text-center" onClick={openLoginModal}>
                <div className="flex justify-center gap-2 items-center">
                  <KlardaLogo1 /> {t("sign_in")}
                </div>
              </Button>
            ),
          },
        ]),
  ];

  return (
    <>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: isMenuOpen ? "auto" : 0 }}
        className="overflow-hidden lg:hidden bg-white shadow-md overflow-y-auto max-h-[calc(100vh-60px)]"
      >
        <Menu
          selectedKeys={[current]}
          mode="vertical"
          className="border-none"
          items={mobileMenuItems}
          onClick={({ key }) => handleMenuClick(key)}
        />
      </motion.div>
    </>
  );
});

MobileMenu.displayName = "MobileMenu";

// DesktopMenu Component
const DesktopMenu: React.FC<{
  current: string;
  menuItems: any[];
  handleMenuClick: (path: string) => void;
  setIsDonateModalOpen: (open: boolean) => void;
}> = React.memo(({ current, menuItems, handleMenuClick, setIsDonateModalOpen }) => {
  const t = useTranslations("home.header");

  return (
    <div className="hidden lg:flex justify-between items-center max-w-[1440px] mx-auto px-4 md:px-8 lg:px-[20px] py-2">
      <div className="flex-grow">
        <Menu
          selectedKeys={[current]}
          mode="horizontal"
          className="border-none flex-grow font-semibold text-base -ml-4"
          items={menuItems}
          onClick={({ key }) => handleMenuClick(key)}
        />
      </div>
      <div className="flex items-center gap-2">
        <Button
          className="flex items-center gap-2 rounded-xl bg-black font-semibold text-white text-base"
          href="https://vi.klarda.com/"
          target="_blank"
        >
          <RiseOutlineIcon />
          {t("klarda_vn")}
        </Button>
        <Button
          icon={<DonateIcon />}
          className="border-[#046C4E] text-[#046C4E] bg-[#DEF7EC] hover:bg-[#046C4E] hover:text-white hover:border-[#046C4E] transition-colors"
          onClick={() => setIsDonateModalOpen(true)}
        >
          Donate
        </Button>
      </div>
    </div>
  );
});

DesktopMenu.displayName = "DesktopMenu";

// UserLoggedAction Component
const UserLoggedAction: React.FC<{ dropdownCurrency: ItemType[]; dropdownTimezone: ItemType[] }> = () => {
  const { user } = useAuth();
  const userAvt = useMemo(
    () =>
      !isEmpty(user?.profile_information?.avatar)
        ? `${IMAGE_CDN}${user?.profile_information?.avatar}`
        : "https://api.dicebear.com/7.x/miniavs/svg?seed=2",
    [user?.profile_information?.avatar],
  );

  return (
    <>
      <style>
        {`
          @keyframes rotate-gradient {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          .user-avatar-wrapper {
            position: relative;
            display: inline-block;
          }
          .user-avatar-wrapper::before {
            content: '';
            position: absolute;
            top: -3px; left: -3px; right: -3px; bottom: -3px;
            border-radius: 50%;
            background: conic-gradient(from 0deg, #ff00ff, #00ff00, #00ffff, #ff00ff);
            animation: rotate-gradient 10s linear infinite;
          }
          .user-avatar-wrapper::after {
            content: '';
            position: absolute;
            top: -1px; left: -1px; right: -1px; bottom: -1px;
            background: white;
            border-radius: 50%;
          }
        `}
      </style>
      <KlardaProductsDropdown />

      <ActiveUser />
      <UserPopover>
        <div className="user-avatar-wrapper cursor-pointer ml-1" style={{ zIndex: 10 }}>
          <Avatar src={userAvt} className="!border-2 !border-solid !border-white z-10" size="default" alt="user avt" />
        </div>
      </UserPopover>
    </>
  );
};

// GuestAction Component
const GuestAction: React.FC<{ dropdownCurrency: ItemType[]; dropdownTimezone: ItemType[] }> = () => {
  const t = useTranslations("home.header");
  const { openLoginModal } = useAuth();

  return (
    <>
      <ActiveUser />
      <Button type="primary" size="large" className="text-white border-none rounded-xl" onClick={openLoginModal}>
        <div className="flex gap-2 items-center">
          <KlardaLogo1 /> {t("sign_in")}
        </div>
      </Button>
    </>
  );
};

// KlardaProductsDropdown Component
const KlardaProductsDropdown: React.FC<{ onProductSelect?: (product: string) => void }> = ({ onProductSelect }) => {
  const t = useTranslations("home.header");

  const items = [
    { icon: <KlardaBubbleIcon />, label: "Klarda Bubble", href: "https://bubbles.klarda.com" },
    { icon: <WhisperIcon />, label: "Klarda Whisper", href: "https://t.me/klardawhisperbot" },
    { icon: <CalendarEventIcon />, label: "Klarda Screener", href: "https://screener.klarda.com" },
    { icon: <ApiOutlined />, label: "Klarda APIs", href: "https://inside.klarda.com/apis" },
  ];

  const menuItems: MenuProps["items"] = [
    {
      key: "dashboard-menu",
      label: (
        <div className="py-2">
          <div className="font-semibold text-base text-opacity-[88]">{t("klarda_products")}</div>
          <Divider className="m-0 p-0" />
          <div className="flex flex-col gap-3 pt-2">
            {items.map((item, index) => (
              <div
                key={index}
                className="flex items-center gap-3 bg-[#FAF7FF] hover:bg-purple-200 border-none rounded-lg !text-primary !p-3 w-full shadow-md"
                onClick={() => {
                  window.open(item.href, "_blank");
                }}
              >
                {React.cloneElement(item.icon as React.ReactElement, { className: "text-[28px] w-8 h-8" })}
                <span className="font-semibold">{item.label}</span>
              </div>
            ))}
          </div>
        </div>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{ items: menuItems, onClick: e => onProductSelect?.(e.key as string), className: "!m-0 !p-0" }}
      trigger={["click"]}
    >
      <Button className="size-10 border-none rounded-lg flex items-center bg-purple-100 gap-1 text-primary font-semibold justify-center">
        <AppstoreOutlined />
      </Button>
    </Dropdown>
  );
};

export default React.memo(Header);
